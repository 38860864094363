/* eslint-disable eslint-comments/no-restricted-disable, max-lines -- File is too complex to refactor right now */

/* eslint-disable sonarjs/cognitive-complexity -- TODO: Refactor this file */
import type { MutableRefObject } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import type { AnyExtension, EditorEvents, Extensions } from "@tiptap/core";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Focus from "@tiptap/extension-focus";
import Gapcursor from "@tiptap/extension-gapcursor";
import HardBreak from "@tiptap/extension-hard-break";
import type { Level } from "@tiptap/extension-heading";
import Heading from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import History from "@tiptap/extension-history";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import Typography from "@tiptap/extension-typography";
import { EditorContent, useEditor } from "@tiptap/react";
import type { Editor, JSONContent } from "@tiptap/react";
import classNames from "classnames";
import { debounce, isFunction } from "lodash";
import { lowlight } from "lowlight/lib/core";
import { Helmet } from "react-helmet";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { EntitiesExtension } from "@circle-react/components/shared/uikit/TipTap/Extensions/CustomExtensions/Entities";
import {
  generateLocalSgidToObjectMap,
  getSgidValuesFromHTML,
} from "@circle-react/components/shared/uikit/TipTap/utilities/sgidForEditorContent";
import { FileExtension } from "@circle-react/components/shared/uikit/TipTapBlockEditor/FileExtension";
import { useFileUploadModal } from "@circle-react/components/shared/uikit/TipTapBlockEditor/FileExtension/FileUploadModal";
import { InlineEmojiExtension } from "@circle-react/components/shared/uikit/TipTapBlockEditor/InlineEmojiExtension";
import { CustomizedLink } from "@circle-react-shared/uikit/TipTap/Extensions/CustomExtensions/CustomizedLink";
import { Mentions } from "@circle-react-shared/uikit/TipTap/Extensions/CustomExtensions/Mentions";
import { CustomizedUnderline } from "@circle-react-shared/uikit/TipTap/Extensions/CustomizedUnderline";
import { CTAExtension } from "@circle-react-shared/uikit/TipTapBlockEditor/CTAExtension";
import { DisableModEnterShortcut } from "@circle-react-shared/uikit/TipTapBlockEditor/DisableModEnterShortcut";
import { EmbedLoaderExtension } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedLoader";
import { FileLoaderExtension } from "@circle-react-shared/uikit/TipTapBlockEditor/FileLoader";
import { useGiphyPickerModal } from "@circle-react-shared/uikit/TipTapBlockEditor/GiphyPickerModal";
import { PollExtension } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension";
import { usePollModal } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal";
import { TrailingNode } from "@circle-react-shared/uikit/TipTapBlockEditor/TrailingNodeExtension";
import { TextModifiersMenu } from "@circle-react-uikit/TipTapBlockEditor/TextModifiersMenu";
import { AiCopilotExtension } from "./AiCopilotBlock";
import { useCTASettingsModal } from "./CTAExtension/CTASettingsModal";
import { CustomizedListItem } from "./CustomizedListItemExtension";
import { Embeds } from "./EmbedExtension";
import { useEmbedModal } from "./EmbedExtension/EmbedModal";
import { Image } from "./ImageExtension";
import { useImagePickerModal } from "./ImageExtension/ImageModal";
import SlashCommands from "./SlashCommands";
import { usePlanUpgradeModal } from "./hooks/usePlanUpgradeModal";
import { useShowCopilot } from "./hooks/useShowCopilot";
import "./styles.scss";

interface DisabledMap {
  [key: string]: boolean;
}

const isAnyExtension = (
  extension: AnyExtension | false,
): extension is AnyExtension => Boolean(extension);

export interface TipTapBlockEditorProps {
  aiCopilotTitle?: string;
  className?: string;
  disabledExtensions?: any[];
  disabledSlashMenuCategories?: any[];
  disabledSlashMenuCommands?: any[];
  disableMentionClicks?: boolean;
  editorClassName?: string;
  editorRef?: MutableRefObject<Editor | null>;
  inlineAttachments?: any[];
  onChange?: (json: JSONContent) => void;
  onFocusChange?: (isFocused: boolean) => void;
  onLocalAttachmentsChange?: (value: any) => void;
  onLocalSgidToObjectMapChange?: (value: any) => void;
  openInternalLinksInCurrentTab?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  sgidToObjectMap?: any;
  shouldPreviewFilesAsLinks?: boolean;
  shouldShowTextModifiersMenu?: boolean;
  showListsInTextModifierMenu?: boolean;
  spaceId?: number;
  textModifierPlacement?: "top" | "bottom";
  maxFileUploadSize?: number;
  toolbars?: any[];
  value?: any;
  shouldRefetch?: boolean;
  headingLevels?: Level[];
  isWithinEmailEditor?: boolean;
}

export interface TipTapBlockEditorContext {
  addToLocalInlineAttachments?: (args: any) => void;
  addToLocalSgidToObjectMap?: (args: any) => void;
  aiCopilotTitle?: string;
  disableMentionClicks?: boolean;
  inlineAttachments?: any[];
  localInlineAttachments?: any[];
  localSgidToObjectMap?: any;
  sgidToObjectMap?: any;
  shouldPreviewFilesAsLinks?: boolean;
  spaceId?: number;
  type?: "block";
  isWithinEmailEditor?: boolean;
}

const BlockEditorContext = createContext<TipTapBlockEditorContext | null>(null);
BlockEditorContext.displayName = "BlockEditorContext";

export const useBlockEditorContext = () => useContext(BlockEditorContext);

import(
  /* webpackChunkName: "HighlightJs_CommonLanguages" */ "highlight.js/lib/common"
)
  .then(module => {
    module.default.highlightAll();
  })
  .catch(console.error);

export const TipTapBlockEditor = ({
  aiCopilotTitle,
  className,
  disabledExtensions: disabledExtensionsArray = [],
  disabledSlashMenuCategories: disabledSlashMenuCategoriesArray = [],
  disabledSlashMenuCommands: disabledSlashMenuCommandsArray = [],
  disableMentionClicks = false,
  editorClassName,
  editorRef,
  inlineAttachments = [],
  onChange,
  onFocusChange,
  onLocalAttachmentsChange,
  onLocalSgidToObjectMapChange,
  openInternalLinksInCurrentTab = true,
  placeholder,
  readOnly = false,
  sgidToObjectMap = {},
  shouldPreviewFilesAsLinks = false,
  shouldShowTextModifiersMenu = true,
  spaceId,
  textModifierPlacement = "top",
  showListsInTextModifierMenu = false,
  maxFileUploadSize,
  toolbars,
  value,
  shouldRefetch = false,
  headingLevels = [2, 3],
  isWithinEmailEditor = false,
}: TipTapBlockEditorProps) => {
  const embedModal = useEmbedModal();
  const imagePickerModal = useImagePickerModal();
  const fileUploadModal = useFileUploadModal();
  const ctaSettingsModal = useCTASettingsModal();
  const giphyPickerModal = useGiphyPickerModal();
  const pollModal = usePollModal();
  const [localSgidToObjectMap, setLocalSgidToObjectMap] = useState<any>({});
  const [localInlineAttachments, setLocalInlineAttachments] = useState<any>([]);
  const [isFocused, , setIsFocused] = useBoolean(false);
  const { shouldShowCopilot: canUseCopilot, shouldShowPlanUpgradeModal } =
    useShowCopilot();
  const { openUpgradeModal } = usePlanUpgradeModal();

  const [
    disabledExtensions,
    disabledSlashMenuCategories,
    disabledSlashMenuCommands,
  ]: DisabledMap[] = [
    disabledExtensionsArray,
    disabledSlashMenuCategoriesArray,
    disabledSlashMenuCommandsArray,
  ].map(array => Object.fromEntries(array.map(key => [key, true])));

  disabledExtensions["ai_copilot"] =
    disabledExtensions["ai_copilot"] ||
    !(canUseCopilot || shouldShowPlanUpgradeModal) ||
    readOnly;

  const addToLocalSgidToObjectMap = ({
    sgid,
    object,
  }: {
    sgid: string;
    object: any;
  }) => {
    setLocalSgidToObjectMap((prevState: any) => ({
      ...prevState,
      [sgid]: object,
    }));

    if (isFunction(onLocalSgidToObjectMapChange)) {
      const newMap = { ...localSgidToObjectMap, [sgid]: object };
      onLocalSgidToObjectMapChange(newMap);
    }
  };

  const addToLocalInlineAttachments = (attachment: any) => {
    setLocalInlineAttachments((prevState: any) => {
      const newState = [...prevState, attachment];
      if (isFunction(onLocalAttachmentsChange)) {
        onLocalAttachmentsChange(newState);
      }
      return newState;
    });
  };

  const showImagePickerModal = (args: any) =>
    imagePickerModal.show({
      ...args,
      addToLocalInlineAttachments,
      isWithinEmailEditor,
    });

  const showGiphyPickerModal = (args: any) =>
    giphyPickerModal.show({
      ...args,
      addToLocalInlineAttachments,
    });

  const showEmbedModal = (args: any) =>
    embedModal.show({ ...args, addToLocalSgidToObjectMap });

  const showFileUploadModal = (args: any) =>
    fileUploadModal.show({
      ...args,
      maxFileUploadSize,
      addToLocalInlineAttachments,
      addToLocalSgidToObjectMap,
    });

  const showPollModal = (args: any) =>
    pollModal.show({ ...args, addToLocalSgidToObjectMap });

  const showCTASettingsModal = (args: any) =>
    ctaSettingsModal.show({ ...args, addToLocalSgidToObjectMap });

  const extensions: Extensions = [
    !disabledExtensions["blockquote"] && Blockquote,
    Bold,
    Italic,
    Strike,
    CustomizedUnderline,
    Document,
    Focus,
    Gapcursor,
    HardBreak,
    !disabledExtensions["code_block"] &&
      CodeBlockLowlight.configure({
        lowlight,
        HTMLAttributes: {
          spellCheck: "false",
        },
      }),
    !disabledExtensions["headings"] &&
      Heading.configure({
        levels: headingLevels,
      }),
    History,
    BulletList,
    CustomizedListItem,
    OrderedList,
    Paragraph,
    Text,
    Typography,
    Highlight.configure({
      multicolor: true,
    }),
    CustomizedLink.configure({
      openInternalLinksInCurrentTab,
      openOnClick: false,
      autolink: false,
    }),
    Placeholder.configure({
      placeholder,
      emptyEditorClass: "is-block-editor-empty text-light",
      emptyNodeClass: "is-empty",
      showOnlyWhenEditable: false,
      showOnlyCurrent: false,
    }),
    !disabledExtensions["horizontal_rule"] && HorizontalRule,
    !disabledExtensions["file"] && FileExtension,
    InlineEmojiExtension(),
    EmbedLoaderExtension,
    !disabledExtensions["ai_copilot"] && AiCopilotExtension,
    !disabledExtensions["slash_commands"] &&
      SlashCommands.configure({
        showEmbedModal,
        showImagePickerModal,
        showFileUploadModal,
        showGiphyPickerModal,
        showPollModal,
        showCTASettingsModal,
        disabledExtensions,
        disabledSlashMenuCategories,
        disabledSlashMenuCommands,
        shouldShowPlanUpgradeModal,
        openUpgradeModal,
        headingLevels,
      }),
    !disabledExtensions["embeds"] && Embeds,
    !disabledExtensions["image"] && Image,
    !disabledExtensions["mention"] && Mentions(),
    !disabledExtensions["entities"] && EntitiesExtension(),
    !disabledExtensions["poll"] && PollExtension,
    !disabledExtensions["cta"] && CTAExtension,
    !readOnly && TrailingNode,
    DisableModEnterShortcut,
    FileLoaderExtension,
    Dropcursor.configure({
      width: 2,
    }),
  ].filter(isAnyExtension);

  useEffect(() => {
    if (isFunction(onFocusChange)) {
      onFocusChange(isFocused);
    }
  }, [isFocused, onFocusChange]);

  const onUpdate = ({ editor }: EditorEvents["update"]) => {
    const json = editor.getJSON();
    onChange && onChange(json);
  };

  const debouncedOnUpdate = debounce(onUpdate, 200);

  const editor = useEditor({
    extensions,
    onUpdate: debouncedOnUpdate,
    onFocus: () => {
      setIsFocused(true);
    },
    onBlur: () => {
      setIsFocused(false);
    },
    content: value,
    editable: !readOnly,
    editorProps: {
      transformPastedHTML(html: any) {
        const sgids: string[] = getSgidValuesFromHTML(html);
        if (sgids.length) {
          void generateLocalSgidToObjectMap({
            sgids,
            setLocalSgidToObjectMap,
          });
        }
        return html;
      },
      attributes: {
        focus: "",
        class: classNames(
          "z-0 max-w-none whitespace-pre-wrap bg-transparent border-none ring-0 circle-block-editor text-dark",
          editorClassName,
        ),
        "data-readonly": readOnly,
      },
    },
  });

  if (editorRef) {
    editorRef.current = editor;
  }

  useEffect(() => {
    shouldRefetch && editor && editor.commands.setContent(value); // Re rending editor after the initial value is changed on condition
  }, [editor, value, shouldRefetch]);

  const handleUpload = (files: FileList) => {
    if (disabledExtensions["file"] || !editor) {
      return;
    }

    editor
      .chain()
      .focus()
      .insertContent(
        Array.from(files).map(file => ({
          type: "file-loader",
          attrs: { file },
        })),
      )
      .run();
  };

  const contextValue: TipTapBlockEditorContext = {
    sgidToObjectMap,
    inlineAttachments,
    localSgidToObjectMap,
    addToLocalSgidToObjectMap,
    localInlineAttachments,
    addToLocalInlineAttachments,
    spaceId,
    aiCopilotTitle,
    shouldPreviewFilesAsLinks,
    type: "block",
    disableMentionClicks,
    isWithinEmailEditor,
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/github.min.css"
          integrity="sha512-0aPQyyeZrWj9sCA46UlmWgKOP0mUipLQ6OZXu8l4IcAmD2u31EPEy9VcIMvl7SoAaKe8bLXZhYoMaE/in+gcgA=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Helmet>
      <BlockEditorContext.Provider value={contextValue}>
        {editor && shouldShowTextModifiersMenu && (
          <TextModifiersMenu
            shouldShowCopilot={!disabledExtensions["ai_copilot"]}
            editor={editor}
            headingLevels={headingLevels}
            placement={textModifierPlacement}
            showListsInTextModifierMenu={showListsInTextModifierMenu}
          />
        )}
        {toolbars &&
          toolbars.map(({ portalId, component: ToolbarComponent }) => (
            <ToolbarComponent
              key={`editor-toolbar-for-${portalId}`}
              editor={editor}
              isEditorFocused={isFocused}
              portalId={portalId}
              showImagePickerModal={showImagePickerModal}
              showFileUploadModal={showFileUploadModal}
              showGiphyPickerModal={showGiphyPickerModal}
              showPollModal={showPollModal}
              disabledExtensions={disabledExtensions}
            />
          ))}
        <EditorContent
          onPaste={e => {
            if (e.clipboardData?.files?.length > 0) {
              handleUpload(e.clipboardData.files);
            }
          }}
          onDrop={e => {
            if (!editor) return null;
            e.preventDefault();
            const dropCoords = { left: e.clientX, top: e.clientY };
            const dropPos = editor.view.posAtCoords(dropCoords);
            if (dropPos) {
              return Array.from(e.dataTransfer.files).forEach(file => {
                editor
                  .chain()
                  .focus()
                  .insertContentAt(dropPos.pos, {
                    type: "file-loader",
                    attrs: { file },
                  })
                  .run();
              });
            }
          }}
          className={className}
          editor={editor}
          data-testid="tip-tap-editor-content"
        />
      </BlockEditorContext.Provider>
    </>
  );
};
