import type {
  ComponentPropsWithRef,
  ForwardedRef,
  MouseEventHandler,
} from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import noop from "lodash/noop";
import type { LinkProps as LinkRouterProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDropdownContext } from "@circle-react-uikit/Dropdown";

interface CommonProps {
  active?: boolean;
  forceClose?: boolean;
  hasDanger?: boolean;
  isDark?: boolean;
  isDisabled?: boolean;
}

interface LinkProps extends CommonProps, LinkRouterProps {}

interface ButtonProps extends CommonProps, ComponentPropsWithRef<"button"> {
  linkType?: ComponentPropsWithRef<"button">["type"];
}

export type ItemLinkProps = LinkProps | ButtonProps;
export type ItemLinkElement = HTMLAnchorElement | HTMLButtonElement;

const isLinkProps = (props: ItemLinkProps): props is LinkProps =>
  "to" in props && Boolean(props.to);

const isButtonProps = (props: ItemLinkProps): props is ButtonProps =>
  !isLinkProps(props);

export const ItemLink = forwardRef<ItemLinkElement, ItemLinkProps>(
  (
    {
      active,
      children,
      forceClose = false,
      hasDanger = false,
      onClick = noop,
      isDark = false,
      isDisabled = false,
      ...rest
    },
    ref,
  ) => {
    const { isOpen, toggle } = useDropdownContext();

    const handleClick: MouseEventHandler<ItemLinkElement> = event => {
      if (forceClose && isOpen) {
        toggle();
      }

      if (isFunction(onClick)) {
        onClick(event);
      }
    };

    const linkClassNames = classNames(
      "block w-full text-left group py-[0.438rem] px-4 rounded-lg hover:bg-tertiary text-xs-plus font-medium transition-colors duration-75",
      {
        "opacity-30": isDisabled,
        "hover:text-dark hover:bg-secondary": !isDark,
        "text-default": !hasDanger && !isDark,
        "bg-tertiary": active && !isDark,
        "text-v2-danger hover:text-v2-danger": hasDanger,
        "text-white hover:bg-tertiary": isDark,
      },
    );

    if (isLinkProps(rest)) {
      return (
        <Link
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- @TODO
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          className={linkClassNames}
          onClick={handleClick}
          {...rest}
        >
          {children}
        </Link>
      );
    }

    if (isButtonProps(rest)) {
      const { linkType = "submit", ...restButton } = rest;
      return (
        <button
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- @TODO
          ref={ref as ForwardedRef<HTMLButtonElement>}
          onClick={handleClick}
          className={linkClassNames}
          {...restButton}
          // eslint-disable-next-line react/button-has-type -- Backwards compatibility with legacy component API
          type={linkType}
          disabled={isDisabled}
        >
          {children}
        </button>
      );
    }

    return null;
  },
);

ItemLink.displayName = "ItemLink";
