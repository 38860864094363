import type { ReactNode } from "react";
import classNames from "classnames";

export interface ListWrapperProps {
  hasBorder?: boolean;
  children: ReactNode;
}

export const ListWrapper = ({
  hasBorder = true,
  children,
}: ListWrapperProps) => (
  <div
    className={classNames("bg-primary px-6 lg:px-9", {
      "border-primary border-b": hasBorder,
    })}
  >
    {children}
  </div>
);
